import store from '@store';
import AxiosLibrary from 'axios';

const AxiosErrorInstance = AxiosLibrary.create();
export const Axios = AxiosLibrary.create();
const CancelToken = AxiosLibrary.CancelToken;
let cancel;

export const makeGetRequest = async (url, params = {}, config = {}) => {
    try {
        cancel && cancel();
        const user = store.getState().user;
        const token = user && user.token;
        const authorization = token ? { Authorization: 'Bearer ' + token } : {};

        return await Axios.get(url, { params, headers: authorization, timeout: 15000, ...config });
    } catch (error) {
        throw error;
    }
};

export const makePostRequest = async (url, data, params = {}, config = {}) => {
    try {
        cancel && cancel();
        params = !!params ? params : {};
        const user = store.getState().user;
        const token = user && user.token;
        const authorization = token ? { Authorization: 'Bearer ' + token } : {};

        return await Axios.post(url, data, {
            params,
            headers: { ...authorization },
            ...config,
            timeout: 15000,
        });
    } catch (error) {
        throw error;
    }
};

export const makePutRequest = async (url, data, params = {}, config = {}) => {
    try {
        cancel && cancel();
        params = !!params ? params : {};
        const user = store.getState().user;
        const token = user && user.token;
        const authorization = token ? { Authorization: 'Bearer ' + token } : {};

        return await Axios.put(url, data, {
            params,
            headers: { ...authorization },
            ...config,
            timeout: 15000,
        });
    } catch (error) {
        throw error;
    }
};

export const makeDeleteRequest = async (url, params = {}, config = {}) => {
    try {
        cancel && cancel();
        params = !!params ? params : {};
        const user = store.getState().user;
        const token = user && user.token;
        const authorization = token ? { Authorization: 'Bearer ' + token } : {};

        return await Axios.delete(url, {
            params,
            headers: { ...authorization },
            ...config,
            timeout: 15000,
        });
    } catch (error) {
        throw error;
    }
};

Axios.interceptors.request.use(config => {
    return { ...config, cancelToken: new CancelToken(c => (cancel = c)) };
});
