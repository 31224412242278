const API_ROUTE = getApiServer();

export const SITE = `${API_ROUTE}/client/siteData`;

export const PRODUCTS = `${API_ROUTE}/client/product`;
export const PRODUCT = (id) => `${API_ROUTE}/client/product/${id}`;

export const CATEGORY = `${API_ROUTE}/client/category`;

export const CONTACT = `${API_ROUTE}/client/contact`;

export const BASKET_ADD = (basketId) => `${API_ROUTE}/client/checkout/${basketId}`;
export const BASKET_CREATE = `${API_ROUTE}/client/checkout`;

export const CHECKOUT_SHIPPING = (basketId) => `${API_ROUTE}/client/checkout/${basketId}/shipping`;
export const CHECKOUT_BILLING = (basketId) => `${API_ROUTE}/client/checkout/${basketId}/billing`;
export const CHECKOUT_RATES = (basketId) => `${API_ROUTE}/client/checkout/${basketId}/rates`;
export const CHECKOUT_COMPLETE = (basketId) => `${API_ROUTE}/client/checkout/${basketId}/complete`;
export const CHECKOUT_ORDER = (basketId) => `${API_ROUTE}/client/checkout/${basketId}`;

export const NEWSLETTER = `${API_ROUTE}/client/newsletter`;
export const COUNTRIES = `${API_ROUTE}/lists/countries`;

function getApiServer() {
    // if (process.env.NODE_ENV === 'development') {
    //     return 'http://localhost:3001';
    // }

    return 'https://api.merlinpanel.com/v1';
}
